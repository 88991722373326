/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  header {
    position: fixed !important;
    z-index: 1000;
  }

  .body-section {
    padding-top: 100px;
  }

  .navbar-nav .dropdown-menu {
    position: static !important;
    inset: 0px !important;
    transform: none !important;
  }

  .feature-img img {
    width: 100%;
    aspect-ratio: 16/9;
  }

  .mobileMenu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 50%;
    transform: translateX(-100%);
    transition: all ease 0.25s;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  .open {
    transform: translateX(0);
    z-index: 11;
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: rgba (0, 0, 0, 0.5);
    display: none;
  }
  .open {
    display: block;
  }

  .navbar-toggler {
    margin-top: 20px;
  }

  .ln-glb {
    display: none !important;
  }

  .top-right-hd li {
    padding: 5px 16px !important;
  }

  .top-left-hd li:first-child {
    display: none !important;
  }

  .top-right-hd li:last-child {
    padding-right: 0 !important;
    border-right: 0 !important;
  }

  .top-left-hd li:nth-child(2) {
    border-right: none !important;
    padding-left: 5px !important;
  }

  .select-options {
    left: 0px !important;
  }

  .select-options ul li:first-child {
    display: block !important;
  }

  .add-post {
    width: 100%;
    margin-left: 0px !important;
    margin-top: 20px;
  }

  .add-task {
    width: 100%;
    margin-left: 0px !important;
    margin-top: 10px;
  }

  .responsive-search {
    display: block !important;
    width: 50%;
  }

  .pages-dropdown {
    margin-top: 0x !important;
    padding-top: 0px !important;
  }

  .dropdown .pages-dropdown:after {
    opacity: 0 !important;
  }

  .pages-dropdown .link-item:hover {
    border-left: 2px solid #ff7555 !important;
  }

  .user-request-dropdown {
    right: -120px !important;
    top: 29px !important;
  }

  .dropdown .user-request-dropdown:after {
    display: none;
  }

  .notification-dropdown {
    right: -20px !important;
    top: 29px !important;
  }

  .dropdown .notification-dropdown:after {
    display: none;
  }

  .message-dropdown {
    margin-right: -140px !important;
    top: 0px !important;
  }

  .dropdown .message-dropdown:after {
    display: none;
  }

  .mt-15 {
    margin-top: 10px !important;
  }

  .achive-text {
    padding: 10px 0 !important;
  }

  .post-buttons {
    text-align: left !important;
  }

  .jobs-owl .owl-nav .owl-prev {
    left: -20px !important;
  }

  .jobs-owl .owl-nav .owl-next {
    right: -20px !important;
  }

  .mt-80 {
    margin-top: 78px;
  }

  .subcribe-input {
    margin-top: 20px !important;
  }

  .footer-links {
    margin-top: 50px !important;
  }

  .social-icons {
    text-align: left !important;
    padding-top: 15px !important;
  }

  .title-bar {
    margin-top: 101px;
  }

  .browse-banner {
    padding: 30px !important;
    margin-top: 80px;
    background-image: -moz-linear-gradient(
      75deg,
      rgb(255, 69, 0) 0%,
      rgb(255, 45, 85) 100%
    ) !important;
    background-image: -webkit-linear-gradient(
      75deg,
      rgb(255, 69, 0) 0%,
      rgb(255, 45, 85) 100%
    ) !important;
    background-image: -ms-linear-gradient(
      75deg,
      rgb(255, 69, 0) 0%,
      rgb(255, 45, 85) 100%
    ) !important;
  }

  .bbnr-right {
    float: left !important;
    padding-top: 30px !important;
    margin-left: 20px;
    width: 100%;
  }

  .bbnr-left {
    text-align: center;
  }

  .gl-btn {
    display: none;
  }

  .main-p-pagination {
    text-align: left !important;
  }

  .sort-list-dt {
    margin-right: 0 !important;
    display: none !important;
  }

  .bp-block {
    margin-right: 0 !important;
  }

  .search-link {
    display: none !important;
  }

  .mtp_30 {
    margin-top: 30px;
  }

  .job-right-dt78 {
    float: left !important;
    text-align: left !important;
    width: 100%;
    margin-top: 20px;
    padding-left: 67px;
  }

  .job-price78 {
    text-align: left !important;
  }

  .grid-list {
    margin-left: 0 !important;
  }

  .date_selector {
    float: left !important;
    width: 100%;
    margin-top: 20px;
  }

  .dash_noti {
    line-height: 1.3 !important;
  }

  .mb80 {
    margin-bottom: 80px;
  }

  .mb30 {
    margin-bottom: 30px;
  }

  .message-inner-dt > p {
    width: 100% !important;
  }

  .main-conversation-box {
    border-left: 1px solid #e5e5e5;
  }

  .mf-field input {
    width: 77% !important;
  }

  .delete_icon {
    float: left !important;
  }

  .tt_left {
    text-align: center !important;
    padding-top: 0 !important;
  }

  .tt_left h4 {
    text-align: center !important;
  }

  .tt_left span {
    text-align: center !important;
  }

  .tt_left .avialable {
    text-align: center !important;
  }

  .bookmark_img img {
    margin-bottom: 30px !important;
  }

  .ff_rating {
    padding-bottom: 0 !important;
  }

  .mtb_4 {
    padding-top: 30px !important;
  }

  .applied_candidates_item {
    text-align: center;
  }

  .applied_candidates_dt {
    display: block !important;
  }

  .candi_dt {
    padding-top: 30px !important;
    margin-left: 0 !important;
    text-align: center;
  }

  .rating_candi {
    display: inline-block !important;
    width: 100% !important;
    text-align: center !important;
  }

  .rating_candi .star {
    margin-left: 0px !important;
    text-align: center !important;
    display: inline-block !important;
  }

  .fixed_delivery {
    text-align: center !important;
  }

  .btn_link24 {
    text-align: center !important;
  }

  .account_hd_right {
    float: left;
    width: 100%;
  }

  .main_lg_btn {
    float: left !important;
    margin-top: 30px;
  }

  .pjfaq80 {
    margin-top: 80px;
  }

  .cards_right {
    display: none;
  }

  .blog_img {
    width: 100% !important;
  }

  .blog_dt {
    width: 100% !important;
  }

  .cmmnt_dt {
    width: 78% !important;
  }

  .comnt_img {
    width: 50px !important;
    height: 50px !important;
  }

  .post_img {
    display: none;
  }

  .post_input {
    width: 100% !important;
  }

  .blog_search {
    margin-top: 80px;
  }

  .abt142 p {
    width: 91% !important;
  }

  .about_video {
    margin-top: 50px;
  }

  .contact_form {
    margin-top: 80px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  header {
    position: fixed !important;
    z-index: 1000;
  }

  .body-section {
    padding-top: 100px;
  }

  .feature-img img {
    width: 100%;
    aspect-ratio: 16/9;
  }

  .mobileMenu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 50%;
    transform: translateX(-100%);
    transition: all ease 0.25s;
    overflow: auto;
    scroll-behavior: auto;
  }
  .open {
    transform: translateX(0);
    z-index: 11;
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: rgba (0, 0, 0, 0.5);
    display: none;
  }
  .open {
    display: block;
  }

  .navbar-toggler {
    margin-top: 10px;
  }

  .top-left-hd li:nth-child(2) {
    border-right: none !important;
  }

  .ln-glb {
    display: none !important;
  }

  .add-post {
    width: 100%;
    margin-left: 0px !important;
    margin-top: 10px;
  }

  .add-task {
    width: 100%;
    margin-left: 0px !important;
    margin-top: 10px;
  }

  .responsive-search {
    display: block !important;
  }

  .dropdown .pages-dropdown:after {
    opacity: 0 !important;
  }

  .pages-dropdown {
    margin-top: 0x !important;
    padding-top: 0px !important;
  }

  .pages-dropdown .link-item:hover {
    border-left: 2px solid #ff7555 !important;
  }

  .user-request-dropdown {
    right: -60px !important;
    top: 29px !important;
  }

  .dropdown .user-request-dropdown:after {
    display: none;
  }

  .notification-dropdown {
    right: 0px !important;
    top: 29px !important;
  }

  .dropdown .notification-dropdown:after {
    display: none;
  }

  .message-dropdown {
    right: -70px !important;
    top: 0px !important;
  }

  .dropdown .message-dropdown:after {
    display: none;
  }

  .mt-15 {
    margin-top: 10px !important;
  }

  .achive-text {
    padding: 10px 0 !important;
  }

  .post-buttons {
    text-align: left !important;
  }

  .mt-80 {
    margin-top: 78px;
  }

  .footer-links {
    margin-top: 50px !important;
  }

  .social-icons {
    text-align: left !important;
    padding-top: 15px !important;
  }

  .title-bar {
    margin-top: 101px;
  }

  .browse-banner {
    padding: 30px !important;
    margin-top: 80px;
    background-image: -moz-linear-gradient(
      75deg,
      rgb(255, 69, 0) 0%,
      rgb(255, 45, 85) 100%
    ) !important;
    background-image: -webkit-linear-gradient(
      75deg,
      rgb(255, 69, 0) 0%,
      rgb(255, 45, 85) 100%
    ) !important;
    background-image: -ms-linear-gradient(
      75deg,
      rgb(255, 69, 0) 0%,
      rgb(255, 45, 85) 100%
    ) !important;
  }

  .gl-btn {
    display: none;
  }

  .main-p-pagination {
    text-align: left !important;
  }

  .sort-list-dt {
    margin-right: 0 !important;
    display: none !important;
  }

  .bp-block {
    margin-right: 0 !important;
  }

  .search-link {
    display: none !important;
  }

  .mtp_30 {
    margin-top: 30px;
  }

  .grid-list {
    margin-left: 0 !important;
  }

  .dash_noti {
    line-height: 1.3 !important;
  }

  .mb80 {
    margin-bottom: 80px;
  }

  .mb30 {
    margin-bottom: 30px;
  }

  .message-inner-dt > p {
    width: 90% !important;
  }

  .mb30 {
    margin-bottom: 30px;
  }

  .main-conversation-box {
    border-left: 1px solid #e5e5e5;
  }

  .mf-field input {
    width: 79.6% !important;
  }

  .delete_icon {
    float: left !important;
  }

  .tt_left {
    text-align: center !important;
    padding-top: 0 !important;
  }

  .tt_left h4 {
    text-align: center !important;
  }

  .tt_left span {
    text-align: center !important;
  }

  .tt_left .avialable {
    text-align: center !important;
  }

  .bookmark_img img {
    margin-bottom: 30px !important;
  }

  .ff_rating {
    padding-bottom: 0 !important;
  }

  .mtb_4 {
    padding-top: 30px !important;
  }

  .pjfaq80 {
    margin-top: 80px;
  }

  .blog_img {
    width: 100% !important;
  }

  .blog_dt {
    width: 100% !important;
  }

  .cmmnt_dt {
    width: 87% !important;
  }

  .comnt_img {
    width: 50px !important;
    height: 50px !important;
  }

  .post_input {
    width: 85% !important;
  }

  .blog_search {
    margin-top: 80px;
  }

  .about_video {
    margin-top: 50px;
  }

  .contact_form {
    margin-top: 80px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  header {
    position: fixed !important;
    z-index: 1000;
  }

  .body-section {
    padding-top: 100px;
  }

  .feature-img img {
    width: 100%;
    aspect-ratio: 16/9;
  }

  .mobileMenu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 30%;
    transform: translateX(-100%);
    transition: all ease 0.25s;
    overflow: auto;
    scroll-behavior: auto;
  }
  .open {
    transform: translateX(0);
    z-index: 11;
  }

  .navbar-toggler {
    margin-top: 10px;
  }

  .add-post {
    width: 100%;
    margin-left: 0px !important;
    margin-top: 20px;
  }

  .add-task {
    width: 100%;
    margin-left: 0px !important;
    margin-top: 10px;
  }

  .responsive-search {
    display: block !important;
  }

  .dropdown .pages-dropdown:after {
    opacity: 0 !important;
  }

  .pages-dropdown {
    margin-top: 0x !important;
    padding-top: 0px !important;
  }

  .pages-dropdown .link-item:hover {
    border-left: 2px solid #ff7555 !important;
  }

  .user-request-dropdown {
    right: -50px !important;
    top: 29px !important;
  }

  .dropdown .user-request-dropdown:after {
    display: none;
  }

  .notification-dropdown {
    right: 0px !important;
    top: 29px !important;
  }

  .dropdown .notification-dropdown:after {
    display: none;
  }

  .message-dropdown {
    margin-right: -50px !important;
    top: 0px !important;
  }

  .dropdown .message-dropdown:after {
    display: none;
  }

  .post-buttons {
    text-align: left !important;
  }

  .browse-banner {
    display: none;
  }

  .title-bar {
    margin-top: 101px;
  }

  .gl-btn {
    display: none;
  }

  .main-tabs {
    margin-top: 0px !important;
  }

  .sort-list-dt {
    margin-right: 0 !important;
    display: none !important;
  }

  .bp-block {
    margin-right: 0 !important;
  }

  .search-link {
    display: none !important;
  }

  .grid-list {
    margin-left: 0 !important;
  }

  .mb30 {
    margin-bottom: 30px;
  }

  .main-conversation-box {
    border-left: 1px solid #e5e5e5;
  }

  .mf-field input {
    width: 79% !important;
  }

  .tt_left {
    text-align: center !important;
    padding-top: 0 !important;
  }

  .tt_left h4 {
    text-align: center !important;
  }

  .tt_left span {
    text-align: center !important;
  }

  .tt_left .avialable {
    text-align: center !important;
  }

  .bookmark_img img {
    margin-bottom: 30px !important;
  }

  .ff_rating {
    padding-bottom: 0 !important;
  }

  .mtb_4 {
    padding-top: 30px !important;
  }

  .cards_right {
    display: none;
  }

  .blog_img {
    width: 100% !important;
  }

  .blog_dt {
    width: 100% !important;
  }

  .cmmnt_dt {
    width: 87% !important;
  }

  .comnt_img {
    width: 50px !important;
    height: 50px !important;
  }

  .post_input {
    width: 89% !important;
  }

  .blog_search {
    margin-top: 80px;
  }

  .abt142 p {
    width: 93% !important;
  }

  .about_video {
    margin-top: 50px;
  }

  .contact_form {
    margin-top: 80px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pages152 .pages-dropdown {
    margin-left: -136px !important;
  }

  .add-post {
    display: none;
  }

  .add-task {
    display: none;
  }

  .modal-jb {
    max-width: 570px !important;
  }

  .mb30 {
    margin-bottom: 30px;
  }

  .main-conversation-box {
    border-left: 1px solid #e5e5e5;
  }

  .cmmnt_dt {
    width: 89% !important;
  }

  .comnt_img {
    width: 50px !important;
    height: 50px !important;
  }

  .post_input {
    width: 89% !important;
  }

  .abt142 p {
    width: 93% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .container {
    max-width: 1170px !important;
  }

  .modal-jb {
    max-width: 570px !important;
  }
}
